<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Close Position Clients</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="search-wrapper">
          <el-input
            placeholder="Search"
            v-model="listQuery.search"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch()"
            ></el-button>
          </el-input>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper"></div>
      </el-col>
    </el-row>
    <Table
      tableKey="ClosePositionClients"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :tableActionShowVisibility="false"
      :tableActionVisibility="false"
      :tableActionViewDetailsVisibility="true"
      :tableActionEditVisibility="false"
      :tableActionDeleteVisibility="false"
      :tableLoadingStatus.sync="loading"
      @pagination="handlePagination()"
      @view="handleViewDetails($event)"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import { show } from "@/api/close-position";
import getRoleSlug from '@/store/app.js'

export default {
  name: "ClosePositionClients",
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      config: [
        {
          label: "User",
          prop: "client_name",
          width: "200",
          className: "redFont",
          popover: true,
        },
        {
          label: "Lot",
          prop: "unit_and_lot",
          width: "200",
          className: "redFont",
        },
        {
          label: "Avg Buy Rate",
          prop: "avg_buy",
          width: "",
          className: "redFont",
        },
        {
          label: "Avg Sell Rate",
          prop: "avg_sell",
          width: "",
          className: "blueFont",
        },
        {
          label: "Profit / Loss",
          prop: "pl",
          width: "",
          className: "blueFont",
        },
        {
          label: "Brokerage",
          prop: "broking_charge",
          width: "",
          className: "blueFont",
        },
        {
          label: "Net P/L",
          prop: "nt_pl",
          width: "",
          className: "blueFont",
        },
      ],
      listData: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      data: {

      },
    };
  },
  computed: {},
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id)
    this.getActivePositionClientList();
  },
  methods: {
      handleViewDetails(data) {
      //  console.log(data)
      // return this.$router.push("/admin/dashboard/broker/" + data.id + "&name=" + data.name);
      return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trading-clients/trading-clients-view/" + data.user_id);
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getActivePositionClientList();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getActivePositionClientList();
    },
    getActivePositionClientList() {
      this.loading = true;
      show(this.$route.params.id).then((response) => {
        this.listData = response.data;
        // console.log(this.listData)
        this.loading = false;
      });
    },
  },
};
</script>
